<template>
  <div class="klon-oder-leid-container">
    <app-navigation rootClassName="navigation-root-class-name38"></app-navigation>
    <h1 class="page-title">Klon oder Leid</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Liebe, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1in01NuKOW1heeJxsTtQT1wRTWFvxtdIV/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="klon-oder-leid-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>“Du bist aufgewacht!”</span>
        <br />
        <br />
        <span>“Nein… Ich bin ein Klon deiner Liebe…”</span>
        <br />
        <br />
        <span>“Oh. Sie ist also noch immer schwer krank?”</span>
        <br />
        <br />
        <span>
          “Ja. Aber ich bin auch sie. Und ich bin gesund. Ich bin ohne Krankheit.
          Wir können zusammenleben.”
        </span>
        <br />
        <br />
        <span>
          “Aber… Aber dann ist sie alleine. Du bist wie sie, aber du bist nicht
          sie.”
        </span>
        <br />
        <br />
        <span>“Spielt das eine Rolle?”</span>
        <br />
        <br />
        <span>
          “Wenn es keine Rolle spielen würde, ginge es mir nicht um sie, sondern
          um die Vorteile, die sie mir bringt. Für mich ist Liebe nicht da, um zu
          gewinnen. Für mich liegt die Schönheit in der Aufgabe seiner selbst für
          das völlige Vertrauen in der jeweils anderen Person.”
        </span>
        <br />
        <br />
        <span>
          “Da ich ja sie bin, bin ich mir sicher, sie würde auch wollen, dass du
          glücklich bist.”
        </span>
        <br />
        <br />
        <span>
          “Ich weiss. Das ist doch das Schöne an unserer Liebe. Wir würden alles
          für das Glück des jeweils anderen opfern.”
        </span>
        <br />
        <br />
        <span>
          “Na gut, aber jetzt lebe ich ja bereits. Du schadest mir ja, wenn du
          nicht mit mir zusammen bist. Und ich bin wie sie, nur nicht krank.”
        </span>
        <br />
        <br />
        <span>
          “Es tut mir Leid, aber dich habe ich nie geliebt. Du bist eine Kopie.
          Egal wie exakt gleich du bist, meine Liebe ist krank und ich will für
          sie da sein.”
        </span>
        <br />
        <br />
        <span>
          “Und wenn sich ihre Krankheit verschlechtert? Dann werden wir alle
          unglücklich sein. Das bringt weder ihr, noch dir, noch mir etwas.”
        </span>
        <br />
        <br />
        <span>
          “Es geht doch dabei nicht um gewinnen. Sonst wäre doch Liebe nichts als
          Opportunismus. Wo läge der Wert an Liebe, wenn ich dir zustimmen würde?”
        </span>
        <br />
        <br />
        <span>“Und wenn sie stirbt. Können wir dann zusammen sein?”</span>
        <br />
        <br />
        <span>
          “Ich schätze, wir müssten uns erst kennenlernen. Du bist nicht sie und
          unterscheidest dich mehr mit jeder Sekunde, die du gesund existierst.”
        </span>
        <br />
        <br />
        <span>
          “Und wenn du in der Zeit zurückreisen könntest, um ihre Krankheit zu
          verhindern?”
        </span>
        <br />
        <br />
        <span>
          “Das wäre nicht mehr die Liebe, die ich bis jetzt liebe. Wenn sie das
          will, tue ich das für sie, aber nur dann.”
        </span>
        <br />
        <br />
        <span>“Du nimmst also Leiden aller im Namen der Liebe in Kauf?”</span>
        <br />
        <br />
        <span>
          “Genau wie du und sie sind Liebe und Glück nicht das Gleiche.”
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name27"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'KlonOderLeid',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Klon oder Leid',
    meta: [
      {
        name: 'description',
        content:
          '“Du bist aufgewacht!”\n“Nein… Ich bin ein Klon deiner Liebe…”\n“Oh. Sie ist also noch immer schwer krank?”\n“Ja. Aber ich bin auch sie. Und ich bin gesund. [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Klon oder Leid',
      },
      {
        property: 'og:description',
        content:
          '“Du bist aufgewacht!”\n“Nein… Ich bin ein Klon deiner Liebe…”\n“Oh. Sie ist also noch immer schwer krank?”\n“Ja. Aber ich bin auch sie. Und ich bin gesund. [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.klon-oder-leid-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.klon-oder-leid-download-button {
  text-decoration: none;
}
</style>
