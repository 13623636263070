<template>
  <div class="wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist-container">
    <app-navigation rootClassName="navigation-root-class-name45"></app-navigation>
    <h1 class="page-title">
      Wieso Itras By ein so simples wie grandioses P&amp;P ist
    </h1>
    <span class="page-subtitle">
      Erfahrungsbericht, ursprünglich geposted auf mitoffenemvisier.com am 4ten
      Januar 2021
    </span>
    <div class="text-body">
      <span>
        Dieser Artikel wird in den nächsten Tagen hier übertragen. In der
        Zwischenzeit kann man sich den Artikel auf archive.org auf folgendem Link
        anschauen:
      </span>
    </div>
    <a
      href="https://web.archive.org/web/20220819114007/https://mitoffenemvisier.com/rollenspiel/wieso-itras-by-ein-so-simples-wie-grandioses-pnp-ist/"
      target="_blank"
      rel="noreferrer noopener"
      class="wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist-download-button button"
    >
      <span class="button-text">Artikel auf archive.org lesen</span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name33"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'WiesoItrasByEinSoSimplesWieGrandiosesPenAndPenIst',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Wieso Itras By ein so simples wie grandioses P&P ist',
    meta: [
      {
        name: 'description',
        content:
          'Das Spielkonzept von Itras By begünstigt Offenheit und abrupte Änderungen des Plots. Diesen erschaffen die Spielenden und die Spielleitung gemeinsam.',
      },
      {
        property: 'og:title',
        content:
          'OpenVisor - Wieso Itras By ein so simples wie grandioses P&P ist',
      },
      {
        property: 'og:description',
        content:
          'Das Spielkonzept von Itras By begünstigt Offenheit und abrupte Änderungen des Plots. Diesen erschaffen die Spielenden und die Spielleitung gemeinsam.',
      },
    ],
  },
}
</script>

<style scoped>
.wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist-download-button {
  text-decoration: none;
}
</style>
