<template>
  <div class="games-container">
    <app-navigation rootClassName="navigation-root-class-name17"></app-navigation>
    <h1 class="page-title">Games</h1>
    <div class="text-body">
      <span>
        Drama Games sind meist simpel aufgebaute kollaborative Erlebnisse die von
        wenigen Minuten bis Stunden dauern können. Bei vielen Drama Games erzählt
        man gemeinsam auf originelle Art eine Geschichte, es gibt aber auch andere
        experimentelle Drama Games. Auf dieser Seite gelistet sind die Drama
        Games, die unter der non-profit Marke OpenVisor erstellt wurden:
      </span>
    </div>
    <div class="card-container">
      <summary-games
        rootClassName="summary-games-root-class-name"
      ></summary-games>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name10"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import SummaryGames from '../components/summary-games'
import AppKontakt from '../components/kontakt'

export default {
  name: 'Games',
  props: {},
  components: {
    AppNavigation,
    SummaryGames,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Games',
    meta: [
      {
        name: 'description',
        content:
          'Hier gelistet sind OpenVisor Videospiele und Drama Games. Drama Games sind meist simpel aufgebaute kollaborative Erlebnisse die Minuten bis Stunden dauern.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Games',
      },
      {
        property: 'og:description',
        content:
          'Hier gelistet sind OpenVisor Videospiele und Drama Games. Drama Games sind meist simpel aufgebaute kollaborative Erlebnisse die Minuten bis Stunden dauern.',
      },
    ],
  },
}
</script>

<style scoped>
.games-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
</style>
