<template>
  <div class="versetztes-beduerfnis-container">
    <app-navigation rootClassName="navigation-root-class-name27"></app-navigation>
    <h1 class="page-title">Versetztes Bedürfnis</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1yCXpaWPRsyVCODBB32YTT_hN60fpcpdx/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="versetztes-beduerfnis-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          “Du kannst also in die Vergangenheit reisen. Würdest Du jetzt also ihre
          Wertsachen stehlen? Es ist, wo Du lebst, ja eh schon alles passiert.
          Hier kannst Du einen Verlobungsring für ein Frühstück verkaufen. Oder
          solltest Du nicht direkt das Frühstück aus der Vergangenheit stehlen?
          Oder gar alles, was Du da findest? Ist doch egal, welchen Wohlstand sie
          hatten, denn in der Zeit, in der Du lebst, hat das seit Ewigkeiten keine
          Rolle mehr gespielt. Wieso also sollten wir die Vergangenheit schützen?”
        </span>
        <br />
        <br />
        <span>
          “Du kannst also in die Zukunft reisen. Würdest Du jetzt also ihre
          Wertsachen stehlen? Es ist, wo Du lebst, ja eh dringender, dies zu
          besitzen und was passieren mag, betrifft dich nicht. Hier kannst Du
          einen Ehering für ein Abendessen verkaufen. Oder solltest Du nicht
          direkt das Abendessen aus der Zukunft stehlen? Oder gar alles, was Du da
          findest? Ist doch egal, welchen Wohlstand sie haben werden, denn in der
          Zeit, in der Du lebst, spielt das noch keine Rolle. Wieso also sollten
          wir die Zukunft schützen?”
        </span>
        <br />
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name19"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'VersetztesBeduerfnis',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Versetztes Bedürfnis',
    meta: [
      {
        name: 'description',
        content:
          '“Du kannst also in die Vergangenheit reisen. Würdest Du jetzt also ihre Wertsachen stehlen? Es ist, wo Du lebst, ja eh schon alles passiert. Hier kannst Du einen Verlobungsring für ein Frühstück[...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Versetztes Bedürfnis',
      },
      {
        property: 'og:description',
        content:
          '“Du kannst also in die Vergangenheit reisen. Würdest Du jetzt also ihre Wertsachen stehlen? Es ist, wo Du lebst, ja eh schon alles passiert. Hier kannst Du einen Verlobungsring für ein Frühstück[...]"',
      },
    ],
  },
}
</script>

<style scoped>
.versetztes-beduerfnis-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.versetztes-beduerfnis-download-button {
  text-decoration: none;
}
</style>
