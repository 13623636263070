<template>
  <div class="heiliger-steinkrieg-container">
    <app-navigation rootClassName="navigation-root-class-name32"></app-navigation>
    <h1 class="page-title">Heiliger Stein</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Konflikt, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/1XAZ0eZgt8-rJoWuOnJHj9mLJgG7tfif6/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="heiliger-steinkrieg-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Der Stammeskrieg um den heiligen Stein brennt seit Generationen. Aiooh
          ist das jüngste Stammesmitglied des Stammes, der gerade den Stein bei
          sich hat. Aiooh findet, heilig ist doch nur, was Heil bringt. Der Stein
          aber brachte bis jetzt nur Bosheit. Als wieder darum gekämpft wird und
          wieder Freunde und Familienmitglieder dafür sterben um den Stein zu
          verteidigen, reisst es Aiooh den Faden: Sie starben alle umsonst!
        </span>
        <br />
        <br />
        <span>
          Aiooh zerschlägt den Stein und versenkt die Splitter im See. Der Stamm
          wirft Aiooh vor, böse zu sein. Denn da der Stein nun nicht mehr war,
          waren die Opfer in der Vergangenheit sinnlos: Sie starben alle umsonst!
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name21"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'HeiligerSteinkrieg',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Heiliger Steinkrieg',
    meta: [
      {
        name: 'description',
        content:
          '"Der Stammeskrieg um den heiligen Stein brennt seit Generationen. Aiooh ist das jüngste Stammesmitglied des Stammes, der gerade den Stein bei sich hat. [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Heiliger Steinkrieg',
      },
      {
        property: 'og:description',
        content:
          '"Der Stammeskrieg um den heiligen Stein brennt seit Generationen. Aiooh ist das jüngste Stammesmitglied des Stammes, der gerade den Stein bei sich hat. [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.heiliger-steinkrieg-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.heiliger-steinkrieg-download-button {
  text-decoration: none;
}
</style>
