<template>
  <div class="einsames-schaf-container">
    <app-navigation rootClassName="navigation-root-class-name14"></app-navigation>
    <h1 class="page-title">Einsames Schaf</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Lebenswert, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://drive.google.com/file/d/124fFgZnT2j9gNGse1NFWiX3AoUoJkoBV/view?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="einsames-schaf-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Fast wäre das Lamm von der Klippe gestürzt. Der Schäferhund hatte es
          nach dem Pfeifen des Hirten grade noch erreicht. Das Lamm versucht
          wieder und wieder sich von der Herde zu entfernen, nur um dann wieder
          eingefangen zu werden. Es scheint kein Interesse zu haben an den saftig
          grünen Wiesen. Während die anderen grasen, schaut es über die Klippen
          zum weiten Ozean. Nach etlichen Fluchtversuchen lässt es sich immer
          weniger vom Hund beeindrucken, bis es diesen ganz ignoriert und gebannt
          zum Felsvorsprung an der Küste rennt. Der Hirte schafft es mit schnellem
          Schritt grade noch rechtzeitig, das Lamm einzuholen und fasst es mit
          beiden Armen. Er bringt es selbst zurück zur Herde. Dieses eine Lamm
          sammelt er nun jeweils selbst ein. Tag für Tag. Morgens, Mittags und
          Abends. Doch der Hirte ist schnell genug.
        </span>
        <br />
        <br />
        <span>
          Der Hirte ist frustriert und verzweifelt. Statt Nickerchen machen zu
          können, muss er nun immer auf dieses eine Lamm aufpassen. Was für eine
          Last. Im Gasthof berichtet er dem Wirt von dem Problemlamm. Dieser hält
          inne und fragt den Hirten: “Für dich ist das Lamm eine Last. Das Lamm
          hat sich durchgerungen und ist selbst nach langer Zeit noch
          entschlossen, sich von der Klippe zu werfen. Wenn das Lamm für dich eine
          Last ist, bloss weil du dich deswegen nicht auf dem Feld ausruhen magst,
          was denkst du, bist du für das Lamm?”
        </span>
        <br />
        <br />
        <span>
          Der Hirte fühlt sich entblösst, hatte er sich die Frage doch nie
          gestellt. Das Lamm ist noch so jung, denkt sich der Hirte. Was wisse das
          Lamm schon übers Leben. Die Sache beschäftigt den Hirten weiter, doch er
          findet es falsch, sich dem Willen des Lammes zu beugen. Er bemüht sich
          um das Lamm, streichelt es grosszügig. Doch das Lamm wirkt unbeeindruckt
          und starrt weiter in die Ferne.
        </span>
        <br />
        <br />
        <span>
          Als das Lamm zum Schaf wird und den ersten vollen Wollmantel abgab,
          wandert es wieder zu den Klippen. Der Hirte bringt es nicht übers Herz,
          tatenlos hinzuschauen und holt das Schaf zurück zur Herde. Er streichelt
          es über den nun erwachsenen Kopf, der in die Ferne starrt und seufzt
          tief. Er setzt sich hin, im Schatten eines toten Baumes und lässt sich
          einschlafen.
        </span>
        <br />
      </p>
      <img
        alt="Eine Zeichnung, auf der zu sehen ist, wie das Schaf zur Klippe rennt und der Hirte am Baum angelehnt schläft."
        src="/kurzgeschichten/openvisor_kurzgeschichte_skizze_einsames_schaf-1200w.webp"
        class="shortstory-image"
      />
      <p class="einsames-schaf-text15 shortstory-paragraph">
        Zeichnung von Lorena Zdanewitz.
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name7"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'EinsamesSchaf',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Einsames Schaf',
    meta: [
      {
        name: 'description',
        content:
          '"Fast wäre das Lamm von der Klippe gestürzt. Der Schäferhund hatte es nach dem Pfeifen des Hirten grade noch erreicht. [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Einsames Schaf',
      },
      {
        property: 'og:description',
        content:
          '"Fast wäre das Lamm von der Klippe gestürzt. Der Schäferhund hatte es nach dem Pfeifen des Hirten grade noch erreicht. [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.einsames-schaf-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.einsames-schaf-download-button {
  text-decoration: none;
}
.einsames-schaf-text15 {
  font-style: italic;
  text-align: center;
}
@media(max-width: 991px) {
  .einsames-schaf-text15 {
    font-style: italic;
  }
}
</style>
