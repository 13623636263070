<template>
  <div class="die-legende-vom-wolf-des-suedens-container">
    <app-navigation rootClassName="navigation-root-class-name41"></app-navigation>
    <h1 class="page-title">
      <span>Die Legende vom Wolf des Südens</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>Trailer als low-budget Virtual Production Proof of Concept</span>
      <br />
    </span>
    <iframe
      src="https://www.youtube.com/embed/gaaC1lg-qYU?si=9lNTQKMdJcN-wBTy"
      class="die-legende-vom-wolf-des-suedens-trailer-video embedded-video"
    ></iframe>
    <div class="text-body">
      <span>
        <span>
          &quot;Nachdem Langeweile sie dazu bringt, ihre Pflichten zu
          vernachlässigen, finden uralte, geächtete Kräfte ihren Weg zurück ins
          Licht. Solei, eine aussergewöhnliche Schülerin der arkanen Künste,
          flieht in eine Welt voller Angst und Verzweiflung. Auf einer Reise zu
          sich selbst lernt die junge Frau die Geister der Natur kennen und
          entdeckt ihre wahren magischen Kräfte.&quot;
        </span>
        <br />
        <br />
        <span>
          In diesem Trailer zu einem hypothetischen Kurzfilm experimentierten
          Freunde und ich mit low-budget Virtual Production.
        </span>
        <br />
        <br />
        <span>
          Hermann war zuständig für die Gestaltung, Ausrüstung und Animationen der
          virtuellen Charaktere, stellte Teile der Ausrüstung, machte das Make-Up
          für die Schauspielenden und durfte auch noch ein wenig Statist spielen.
        </span>
        <br />
      </span>
    </div>
    <a
      href="https://visuation.ch/films/wolf-des-s%C3%BCdens"
      target="_blank"
      rel="noreferrer noopener"
      class="die-legende-vom-wolf-des-suedens-download-button button"
    >
      <span class="button-text">offizielle Seite des Trailers</span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name29"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'DieLegendeVomWolfDesSuedens',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Die Legende vom Wolf des Südens',
    meta: [
      {
        name: 'description',
        content:
          'In diesem Trailer zu einem hypothetischen Kurzfilm experimentierten Freunde und ich mit low-budget Virtual Production.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Die Legende vom Wolf des Südens',
      },
      {
        property: 'og:description',
        content:
          'In diesem Trailer zu einem hypothetischen Kurzfilm experimentierten Freunde und ich mit low-budget Virtual Production.',
      },
    ],
  },
}
</script>

<style scoped>
.die-legende-vom-wolf-des-suedens-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.die-legende-vom-wolf-des-suedens-trailer-video {
  margin-bottom: 0px;
}
.die-legende-vom-wolf-des-suedens-download-button {
  text-decoration: none;
}
</style>
