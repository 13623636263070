import { render, staticRenderFns } from "./die-legende-vom-wolf-des-suedens.vue?vue&type=template&id=3defe8df&scoped=true"
import script from "./die-legende-vom-wolf-des-suedens.vue?vue&type=script&lang=js"
export * from "./die-legende-vom-wolf-des-suedens.vue?vue&type=script&lang=js"
import style0 from "./die-legende-vom-wolf-des-suedens.vue?vue&type=style&index=0&id=3defe8df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3defe8df",
  null
  
)

export default component.exports