<template>
  <nav class="navigation-navbar" v-bind:class="rootClassName">
    <header data-thq="thq-navbar" class="navigation-navbar-interactive">
      <router-link to="/" class="navigation-navlink">
        <img alt="OpenVisor Logo" :src="openvisorLogo" class="navigation-logo" />
      </router-link>
      <div
        data-thq="thq-navbar-nav"
        data-role="Nav"
        class="navigation-desktop-menu"
      >
        <nav
          data-thq="thq-navbar-nav-links"
          data-role="Nav"
          class="navigation-nav"
        >
          <router-link to="/" class="navigation-home desktop-menu-text nav-link">
            {{ home }}
          </router-link>
          <router-link to="/ueber-uns" class="desktop-menu-text nav-link">
            {{ ueberUns }}
          </router-link>
          <router-link to="/larpkonzept" class="desktop-menu-text nav-link">
            {{ larpkonzept }}
          </router-link>
          <div
            data-thq="thq-dropdown"
            class="navigation-larps-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              class="desktop-menu-dropdown-toggle mobile-menu-dropdown-toggle"
            >
              <router-link
                to="/larps"
                class="navigation-larps desktop-menu-dropdown-header nav-link"
              >
                {{ larps }}
              </router-link>
              <div
                data-thq="thq-dropdown-arrow"
                class="desktop-menu-arrow-container"
              >
                <svg viewBox="0 0 1024 1024" class="desktop-menu-arrow">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              class="navigation-dropdown-list desktop-menu-dropdown-list"
            >
              <router-link to="/larps/suesse-traeume">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle01"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ suesseTraeume }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/larps/letzte-gelegenheit">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle02"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ letzteGelegenheit }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/larps/conundrum-schatten-ueber-ostmerkopa">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle03"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ conundrumSchattenUeberOstmerkopa }}
                    </span>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
          <div
            data-thq="thq-dropdown"
            class="navigation-games-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              class="desktop-menu-dropdown-toggle"
            >
              <router-link
                to="/games"
                class="navigation-games desktop-menu-dropdown-header nav-link"
              >
                {{ games }}
              </router-link>
              <div
                data-thq="thq-dropdown-arrow"
                class="desktop-menu-arrow-container"
              >
                <svg viewBox="0 0 1024 1024" class="desktop-menu-arrow">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              class="navigation-dropdown-list1 desktop-menu-dropdown-list"
            >
              <router-link to="/games/assemblatron">
                <li
                  data-thq="thq-dropdown"
                  class="navigation-assemblatron-dropdown desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle05"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ assemblatron }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/games/murphys-larp">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle06"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ murphysLarp }}
                    </span>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
          <div
            data-thq="thq-dropdown"
            class="navigation-kurzfilme-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              class="desktop-menu-dropdown-toggle"
            >
              <router-link
                to="/kurzfilme"
                class="navigation-kurzfilme desktop-menu-dropdown-header nav-link"
              >
                Kurzfilme
              </router-link>
              <div
                data-thq="thq-dropdown-arrow"
                class="desktop-menu-arrow-container"
              >
                <svg viewBox="0 0 1024 1024" class="desktop-menu-arrow">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              class="navigation-dropdown-list2 desktop-menu-dropdown-list"
            >
              <router-link to="/kurzfilme/wolf-des-suedens">
                <li
                  data-thq="thq-dropdown"
                  class="navigation-wolf-des-suedens-dropdown desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle08"
                  >
                    <span class="desktop-menu-dropdown-option">
                      Die Legende vom Wolf des Südens (Trailer)
                    </span>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
          <div
            data-thq="thq-dropdown"
            class="navigation-kurzgeschichten-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              class="desktop-menu-dropdown-toggle"
            >
              <router-link
                to="/kurzgeschichten"
                class="navigation-kurzgeschichten desktop-menu-dropdown-header nav-link"
              >
                {{ kurzgeschichten }}
              </router-link>
              <div
                data-thq="thq-dropdown-arrow"
                class="desktop-menu-arrow-container"
              >
                <svg viewBox="0 0 1024 1024" class="desktop-menu-arrow">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              class="navigation-dropdown-list3 desktop-menu-dropdown-list"
            >
              <router-link to="/kurzgeschichten/ein-orden-fuer-eine-gute-tat">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle10"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ einOrdenFuerEineGuteTat }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/rosenteufel">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ rosenteufel }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/einsames-schaf">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ einsamesSchaf }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/gefangener-gorilla">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ gefangenerGorilla }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/lasst-mich-ruhen">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ lasstMichRuhen }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/die-loesung-gegen-leid">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ dieLoesungGegenLeid }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/infinite-deaths">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ infiniteDeathsEnglish }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/versetztes-beduerfnis">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ versetztesBeduerfnis }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link
                to="/kurzgeschichten/was-unterscheidet-himmel-von-hoelle"
              >
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ wasUnterscheidetHimmelVonHoelle }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/leben-statt-ueberleben">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ lebenStattUeberleben }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/getraeumtes-glueck">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ getraeumtesGlueck }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/klon-oder-leid">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ klonOderLeid }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/ich-will-kein-hammer-sein">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ ichWillKeinHammerSein }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/teilung-zweier-welten">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ teilungZweierWelten }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/huhn-rammt-huhn">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ huhnRammtHuhn }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/heiliger-steinkrieg">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ heiligerSteinkrieg }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/unwichtigster-mensch">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ unwichtigsterMensch }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/superkraft-im-starbucks">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ superkraftImStarbucks }}
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link to="/kurzgeschichten/them-or-us">
                <li
                  data-thq="thq-dropdown"
                  class="desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle"
                  >
                    <span class="desktop-menu-dropdown-option">
                      {{ themOrUsEnglish }}
                    </span>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
          <div data-thq="thq-dropdown" class="navigation-blog-dropdown list-item">
            <div
              data-thq="thq-dropdown-toggle"
              class="desktop-menu-dropdown-toggle"
            >
              <router-link
                to="/blog"
                class="navigation-blog desktop-menu-dropdown-header nav-link"
              >
                Blog
              </router-link>
              <div
                data-thq="thq-dropdown-arrow"
                class="desktop-menu-arrow-container"
              >
                <svg viewBox="0 0 1024 1024" class="desktop-menu-arrow">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul
              data-thq="thq-dropdown-list"
              class="navigation-dropdown-list4 desktop-menu-dropdown-list"
            >
              <router-link to="/blog/funktioniert-larp-auch-online">
                <li
                  data-thq="thq-dropdown"
                  class="navigation-funktioniert-online-larp desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle30"
                  >
                    <span class="desktop-menu-dropdown-option">
                      Funktioniert LARP auch online?
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link
                to="/blog/wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist"
              >
                <li
                  data-thq="thq-dropdown"
                  class="navigation-itras-by desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle31"
                  >
                    <span class="desktop-menu-dropdown-option">
                      Wieso Itras By ein so simples wie grandioses P&amp;P ist
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link
                to="/blog/wie-man-ein-gutes-online-larp-macht-und-wie-nicht"
              >
                <li
                  data-thq="thq-dropdown"
                  class="navigation-wie-gutes-online-larp desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle32"
                  >
                    <span class="desktop-menu-dropdown-option">
                      Wie man ein gutes Online LARP macht (und wie nicht)
                    </span>
                  </div>
                </li>
              </router-link>
              <router-link
                to="/blog/wie-tales-inside-eine-einmalige-immersion-erreicht"
              >
                <li
                  data-thq="thq-dropdown"
                  class="navigation-tales-inside-dropdown desktop-menu-list-item list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    class="desktop-menu-dropdown-element desktop-menu-dropdown-toggle navigation-dropdown-toggle33"
                  >
                    <span class="desktop-menu-dropdown-option">
                      Wie Tales Inside eine einmalige Immersion erreicht
                    </span>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
        </nav>
      </div>
      <div data-thq="thq-burger-menu" class="navigation-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navigation-icon10">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navigation-mobile-menu">
        <div
          data-thq="thq-mobile-menu-nav"
          data-role="Nav"
          class="navigation-nav1"
        >
          <div class="navigation-nav-top">
            <div data-thq="thq-close-menu" class="navigation-menu-close">
              <svg viewBox="0 0 1024 1024" class="navigation-icon12">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav
            data-thq="thq-mobile-menu-nav-links"
            data-role="Nav"
            class="mobile-menu-nav-body"
          >
            <router-link to="/" class="mobile-menu-text nav-link">
              {{ home }}
            </router-link>
            <router-link to="/ueber-uns" class="mobile-menu-text nav-link">
              {{ ueberUns }}
            </router-link>
            <router-link to="/larpkonzept" class="mobile-menu-text nav-link">
              {{ larpkonzept }}
            </router-link>
            <div data-thq="thq-dropdown" class="mobile-menu-dropdown list-item">
              <div
                data-thq="thq-dropdown-toggle"
                class="mobile-menu-dropdown-toggle"
              >
                <router-link
                  to="/larps"
                  class="navigation-larps1 mobile-menu-dropdown-header nav-link"
                >
                  {{ larps }}
                </router-link>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="mobile-menu-dropdown-arrow-container"
                >
                  <svg viewBox="0 0 1024 1024" class="mobile-menu-dropdown-arrow">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                class="navigation-larps-dropdown-list dropdown-list"
              >
                <router-link to="/larps/suesse-traeume">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="navigation-dropdown-toggle35"
                    >
                      <span class="navigation-suesse-traeume1 nav-link">
                        {{ suesseTraeume }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/larps/letzte-gelegenheit">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="navigation-dropdown-toggle36"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ letzteGelegenheit }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/larps/conundrum-schatten-ueber-ostmerkopa">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="navigation-dropdown-toggle37"
                    >
                      <span
                        class="navigation-conundrum-schatten-ueber-ostmerkopa1 nav-link"
                      >
                        {{ conundrumSchattenUeberOstmerkopa }}
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
            <div data-thq="thq-dropdown" class="mobile-menu-dropdown list-item">
              <div
                data-thq="thq-dropdown-toggle"
                class="mobile-menu-dropdown-toggle"
              >
                <router-link
                  to="/games"
                  class="navigation-games1 mobile-menu-dropdown-header nav-link"
                >
                  {{ games }}
                </router-link>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="mobile-menu-dropdown-arrow-container"
                >
                  <svg viewBox="0 0 1024 1024" class="mobile-menu-dropdown-arrow">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                class="navigation-games-dropdown-list dropdown-list"
              >
                <router-link to="/games/assemblatron">
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-assemblatron1 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ assemblatron }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/games/murphys-larp">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="navigation-dropdown-toggle40"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ murphysLarp }}
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
            <div data-thq="thq-dropdown" class="mobile-menu-dropdown list-item">
              <div
                data-thq="thq-dropdown-toggle"
                class="mobile-menu-dropdown-toggle"
              >
                <router-link
                  to="/kurzfilme"
                  class="navigation-kurzfilme1 mobile-menu-dropdown-header nav-link"
                >
                  Kurzfilme
                </router-link>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="mobile-menu-dropdown-arrow-container"
                >
                  <svg viewBox="0 0 1024 1024" class="mobile-menu-dropdown-arrow">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                class="navigation-games-dropdown-list1 dropdown-list"
              >
                <router-link to="/kurzfilme/wolf-des-suedens">
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-wolf-des-suedens1 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        Die Legende vom Wolf des Südens (Trailer)
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
            <div data-thq="thq-dropdown" class="mobile-menu-dropdown list-item">
              <div
                data-thq="thq-dropdown-toggle"
                class="mobile-menu-dropdown-toggle"
              >
                <router-link
                  to="/kurzgeschichten"
                  class="navigation-kurzgeschichten1 mobile-menu-dropdown-header nav-link"
                >
                  {{ kurzgeschichten }}
                </router-link>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="mobile-menu-dropdown-arrow-container"
                >
                  <svg viewBox="0 0 1024 1024" class="mobile-menu-dropdown-arrow">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                class="navigation-kurzgeschichten-dropdown-list dropdown-list"
              >
                <router-link to="/kurzgeschichten/ein-orden-fuer-eine-gute-tat">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ einOrdenFuerEineGuteTat }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/rosenteufel">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle45"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ rosenteufel }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/einsames-schaf">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle46"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ einsamesSchaf }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/gefangener-gorilla">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle47"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ gefangenerGorilla }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/lasst-mich-ruhen">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle48"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ lasstMichRuhen }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/die-loesung-gegen-leid">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle49"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ dieLoesungGegenLeid }}
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/infinite-deaths">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle50"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ infiniteDeathsEnglish }}Die Lösung Gegen Leid
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/versetztes-beduerfnis">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle51"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ versetztesBeduerfnis }}Die Lösung Gegen Leid
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link
                  to="/kurzgeschichten/was-unterscheidet-himmel-von-hoelle"
                >
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle52"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ wasUnterscheidetHimmelVonHoelle }}Die Lösung Gegen Leid
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/leben-statt-ueberleben">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle53"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ lebenStattUeberleben }}Die Lösung Gegen Leid
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/getraeumtes-glueck">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle54"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ getraeumtesGlueck }}Ein Orden für eine gute Tat
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/klon-oder-leid">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="navigation-dropdown-toggle55 mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ klonOderLeid }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/ich-will-kein-hammer-sein">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle56"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ ichWillKeinHammerSein }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/teilung-zweier-welten">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle57"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ teilungZweierWelten }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/huhn-rammt-huhn">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle58"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ huhnRammtHuhn }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/heiliger-steinkrieg">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle59"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ heiligerSteinkrieg }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/unwichtigster-mensch">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle60"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ unwichtigsterMensch }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/superkraft-im-starbucks">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle61"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ superkraftImStarbucks }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link to="/kurzgeschichten/them-or-us">
                  <li
                    data-thq="thq-dropdown"
                    class="mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle navigation-dropdown-toggle62"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        {{ themOrUsEnglish }}Lasst mich Ruhen!
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
            <div data-thq="thq-dropdown" class="mobile-menu-dropdown list-item">
              <div
                data-thq="thq-dropdown-toggle"
                class="mobile-menu-dropdown-toggle"
              >
                <router-link
                  to="/blog"
                  class="navigation-blog1 mobile-menu-dropdown-header nav-link"
                >
                  Blog
                </router-link>
                <div
                  data-thq="thq-dropdown-arrow"
                  class="mobile-menu-dropdown-arrow-container"
                >
                  <svg viewBox="0 0 1024 1024" class="mobile-menu-dropdown-arrow">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                class="navigation-blog-dropdown-list dropdown-list"
              >
                <router-link to="/blog/funktioniert-larp-auch-online">
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-funktioniert-online-larp2 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        Funktioniert LARP auch online?
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link
                  to="/blog/wieso-itras-by-ein-so-simples-wie-grandioses-pen-and-paper-ist"
                >
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-itras-by2 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        Wieso Itras By ein so simples wie grandioses P&amp;P ist
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link
                  to="/blog/wie-man-ein-gutes-online-larp-macht-und-wie-nicht"
                >
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-wie-gutes-online-larp2 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        Wie man ein gutes Online LARP macht (und wie nicht)
                      </span>
                    </div>
                  </li>
                </router-link>
                <router-link
                  to="/blog/wie-tales-inside-eine-einmalige-immersion-erreicht"
                >
                  <li
                    data-thq="thq-dropdown"
                    class="navigation-tales-inside1 mobile-menu-list-item list-item"
                  >
                    <div
                      data-thq="thq-dropdown-toggle"
                      class="mobile-menu-dropdown-element mobile-menu-dropdown-toggle"
                    >
                      <span class="mobile-menu-dropdown-option nav-link">
                        Wie Tales Inside eine einmalige Immersion erreicht
                      </span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    themOrUsEnglish: {
      type: String,
      default: 'Them or Us (English)',
    },
    unwichtigsterMensch: {
      type: String,
      default: 'Unwichtigster Mensch',
    },
    infiniteDeathsEnglish: {
      type: String,
      default: 'Infinite Deaths (English)',
    },
    klonOderLeid: {
      type: String,
      default: 'Klon oder Leid',
    },
    dieLoesungGegenLeid: {
      type: String,
      default: 'Die Lösung Gegen Leid',
    },
    versetztesBeduerfnis: {
      type: String,
      default: 'Versetztes Bedürfnis',
    },
    wasUnterscheidetHimmelVonHoelle: {
      type: String,
      default: 'Was unterscheidet Himmel von Hölle?',
    },
    gefangenerGorilla: {
      type: String,
      default: 'Gefangener Gorilla',
    },
    games: {
      type: String,
      default: 'Games',
    },
    superkraftImStarbucks: {
      type: String,
      default: 'Superkraft im Starbucks',
    },
    ichWillKeinHammerSein: {
      type: String,
      default: 'Ich will kein Hammer sein',
    },
    lasstMichRuhen: {
      type: String,
      default: 'Lasst mich Ruhen!',
    },
    lebenStattUeberleben: {
      type: String,
      default: 'Leben statt Überleben',
    },
    heiligerSteinkrieg: {
      type: String,
      default: 'Heiliger Steinkrieg',
    },
    huhnRammtHuhn: {
      type: String,
      default: 'Huhn rammt Huhn',
    },
    murphysLarp: {
      type: String,
      default: "Murphy's LARP",
    },
    getraeumtesGlueck: {
      type: String,
      default: 'Geträumtes Glück',
    },
    conundrumSchattenUeberOstmerkopa: {
      type: String,
      default: 'Conundrum: Schatten über Ostmerkopa',
    },
    einsamesSchaf: {
      type: String,
      default: 'Einsames Schaf',
    },
    assemblatron: {
      type: String,
      default: 'Assemblatron',
    },
    teilungZweierWelten: {
      type: String,
      default: 'Teilung zweier Welten',
    },
    openvisorLogo: {
      type: String,
      default: '/logo_mitoffenemvisier_bw_png-min-200h.png',
    },
    kurzgeschichten: {
      type: String,
      default: 'Kurzgeschichten',
    },
    letzteGelegenheit: {
      type: String,
      default: 'Letzte Gelegenheit',
    },
    home: {
      type: String,
      default: 'Home',
    },
    larps: {
      type: String,
      default: 'LARPs',
    },
    ueberUns: {
      type: String,
      default: 'Über uns',
    },
    einOrdenFuerEineGuteTat: {
      type: String,
      default: 'Ein Orden für eine gute Tat',
    },
    suesseTraeume: {
      type: String,
      default: 'Süsse Träume',
    },
    larpkonzept: {
      type: String,
      default: 'Larpkonzept',
    },
    rosenteufel: {
      type: String,
      default: 'Rosenteufel',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.navigation-navbar {
  width: 100%;
  max-width: 1200px;
}
.navigation-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.navigation-navlink {
  display: contents;
}
.navigation-logo {
  width: 100px;
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}

.navigation-desktop-menu {
  display: flex;
}
.navigation-nav {
  gap: 26px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-home {
  text-decoration: none;
}
.navigation-larps-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navigation-larps {
  text-decoration: none;
}
.navigation-dropdown-list {
  display: none;
}
.navigation-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-games-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navigation-games {
  text-decoration: none;
}
.navigation-dropdown-list1 {
  display: none;
}
.navigation-assemblatron-dropdown {
  text-decoration: none;
}
.navigation-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-kurzfilme-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navigation-kurzfilme {
  text-decoration: none;
}
.navigation-dropdown-list2 {
  display: none;
}
.navigation-wolf-des-suedens-dropdown {
  text-decoration: none;
}
.navigation-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-kurzgeschichten-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navigation-kurzgeschichten {
  text-decoration: none;
}
.navigation-dropdown-list3 {
  display: none;
}
.navigation-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-blog-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navigation-blog {
  text-decoration: none;
}
.navigation-dropdown-list4 {
  display: none;
}
.navigation-funktioniert-online-larp {
  text-decoration: none;
}
.navigation-dropdown-toggle30:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-itras-by {
  text-decoration: none;
}
.navigation-dropdown-toggle31:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-wie-gutes-online-larp {
  text-decoration: none;
}
.navigation-dropdown-toggle32:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-tales-inside-dropdown {
  text-decoration: none;
}
.navigation-dropdown-toggle33:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.navigation-icon10 {
  fill: #ffffff;
  width: 36px;
  cursor: pointer;
  height: 36px;
  padding: 6px;
  transition: 0.3s;
  border-radius: 18px;
  background-color: rgba(100, 100, 100, 0.4);
}
.navigation-icon10:hover {
  opacity: 0.5;
}
.navigation-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 99;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0,0,0,0.8);
  transition-duration: 0.5;
}
.navigation-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navigation-nav-top {
  width: auto;
  display: flex;
  align-items: flex-end;
  margin-left: 90%;
  justify-content: flex-end;
}
.navigation-menu-close {
  display: flex;
}
.navigation-icon12 {
  fill: #ffffff;
  width: 30px;
  cursor: pointer;
  height: 30px;
  margin-top: 30px;
  transition: 0.3s;
  margin-left: -30px;
}
.navigation-icon12:hover {
  opacity: 0.5;
}
.navigation-larps1 {
  text-decoration: none;
}
.navigation-larps-dropdown-list {
  display: none;
}
.navigation-dropdown-toggle35 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle35:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-suesse-traeume1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navigation-dropdown-toggle36 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle36:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle37 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle37:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-conundrum-schatten-ueber-ostmerkopa1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navigation-games1 {
  text-decoration: none;
}
.navigation-games-dropdown-list {
  display: none;
}
.navigation-assemblatron1 {
  text-decoration: none;
}
.navigation-dropdown-toggle40 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle40:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-kurzfilme1 {
  text-decoration: none;
}
.navigation-games-dropdown-list1 {
  display: none;
}
.navigation-wolf-des-suedens1 {
  text-decoration: none;
}
.navigation-kurzgeschichten1 {
  text-decoration: none;
}
.navigation-kurzgeschichten-dropdown-list {
  display: none;
}
.navigation-dropdown-toggle45:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle46:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle47:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle48:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle49:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle50:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle51:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle52:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle53:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle54:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle55 {
  position: relative;
}
.navigation-dropdown-toggle55:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle56:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle57:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle58:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle59:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle60:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle61:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-dropdown-toggle62:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.navigation-blog1 {
  text-decoration: none;
}
.navigation-blog-dropdown-list {
  display: none;
}
.navigation-funktioniert-online-larp2 {
  text-decoration: none;
}
.navigation-itras-by2 {
  text-decoration: none;
}
.navigation-wie-gutes-online-larp2 {
  text-decoration: none;
}
.navigation-tales-inside1 {
  text-decoration: none;
}
.navigation-root-class-name {
  max-width: 1200px;
}





.navigation-root-class-name6 {
  max-width: 1200px;
}
.navigation-root-class-name7 {
  max-width: 1200px;
}
.navigation-root-class-name8 {
  max-width: 1200px;
}
.navigation-root-class-name9 {
  max-width: 1200px;
}
.navigation-root-class-name10 {
  max-width: 1200px;
}
.navigation-root-class-name11 {
  max-width: 1200px;
}
.navigation-root-class-name12 {
  max-width: 1200px;
}
.navigation-root-class-name13 {
  max-width: 1200px;
}
.navigation-root-class-name14 {
  max-width: 1200px;
}
.navigation-root-class-name15 {
  max-width: 1200px;
}
.navigation-root-class-name16 {
  max-width: 1200px;
}
.navigation-root-class-name17 {
  max-width: 1200px;
}
.navigation-root-class-name18 {
  max-width: 1200px;
}
.navigation-root-class-name19 {
  max-width: 1200px;
}
.navigation-root-class-name20 {
  max-width: 1200px;
}
.navigation-root-class-name21 {
  max-width: 1200px;
}
.navigation-root-class-name22 {
  max-width: 1200px;
}
.navigation-root-class-name23 {
  max-width: 1200px;
}
.navigation-root-class-name24 {
  max-width: 1200px;
}
.navigation-root-class-name25 {
  max-width: 1200px;
}
.navigation-root-class-name26 {
  max-width: 1200px;
}
.navigation-root-class-name27 {
  max-width: 1200px;
}
.navigation-root-class-name28 {
  max-width: 1200px;
}
.navigation-root-class-name29 {
  max-width: 1200px;
}
.navigation-root-class-name30 {
  max-width: 1200px;
}
.navigation-root-class-name31 {
  max-width: 1200px;
}
.navigation-root-class-name32 {
  max-width: 1200px;
}
.navigation-root-class-name33 {
  max-width: 1200px;
}
.navigation-root-class-name34 {
  max-width: 1200px;
}
.navigation-root-class-name35 {
  max-width: 1200px;
}
.navigation-root-class-name36 {
  max-width: 1200px;
}
.navigation-root-class-name37 {
  max-width: 1200px;
}
.navigation-root-class-name38 {
  max-width: 1200px;
}
.navigation-root-class-name39 {
  max-width: 1200px;
}
.navigation-root-class-name40 {
  max-width: 1200px;
}
.navigation-root-class-name41 {
  max-width: 1200px;
}
.navigation-root-class-name42 {
  max-width: 1200px;
}
.navigation-root-class-name43 {
  max-width: 1200px;
}
.navigation-root-class-name44 {
  max-width: 1200px;
}
.navigation-root-class-name45 {
  max-width: 1200px;
}
.navigation-root-class-name46 {
  max-width: 1200px;
}
.navigation-root-class-name47 {
  max-width: 1200px;
}
@media(max-width: 991px) {
  .navigation-navbar-interactive {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .navigation-desktop-menu {
    display: none;
  }
  .navigation-burger-menu {
    display: flex;
  }
}
@media(max-width: 767px) {
  .navigation-suesse-traeume1 {
    color: #ffffff;
  }
  .navigation-conundrum-schatten-ueber-ostmerkopa1 {
    color: #ffffff;
  }
}
@media(max-width: 479px) {
  .navigation-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
}
</style>
