<template>
  <div class="huhn-rammt-huhn-container">
    <app-navigation rootClassName="navigation-root-class-name31"></app-navigation>
    <h1 class="page-title">Huhn rammt Huhn</h1>
    <span class="page-subtitle">
      <span>Kurzgeschichte zum Thema Konflikt, Oktober 2023</span>
      <br />
    </span>
    <a
      href="https://docs.google.com/document/d/1RS2zs4Li0gUPUud_QDhVI_61bwIuy08_sMvVLlsoTeU/edit?usp=sharing"
      target="_blank"
      rel="noreferrer noopener"
      class="huhn-rammt-huhn-download-button button"
    >
      <span class="button-text">Als PDF</span>
    </a>
    <div class="text-body">
      <p class="shortstory-paragraph">
        <span>
          Huhn rammt Huhn. Das Blatt fällt runter. Huhn mit Blatt im Schnabel
          rennt weg. Doch das wird selbst von Huhn gerammt. Eben diesem wird das
          Blatt aber von wiederum einem anderen Huhn weggepickt.
        </span>
        <br />
        <br />
        <span>
          Welches Huhn das erste war, welches dieses Blatt statt Futter
          versehentlich aufgepickt hatte, weiss man nicht mehr. Auf jeden Fall
          dachte ein anderes, es wäre was wertvolles, wenn es schon aufgepickt
          wird und pickte es dem andern Huhn weg. Die Hühner, die das sahen,
          dachten sich dann auch, das muss wertvoll sein, wenn darum gestritten
          wird, und stritten fortan auch drum.
        </span>
        <br />
        <br />
        <span>
          Nun brennt der Kampf um das für Hühner nährstofflose Blatt weiter, nur
          weil selbst ein so einfaches Tier Neid und Gier kennt.
        </span>
      </p>
    </div>
    <app-kontakt rootClassName="kontakt-root-class-name20"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'HuhnRammtHuhn',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Huhn rammt Huhn',
    meta: [
      {
        name: 'description',
        content:
          '"Huhn rammt Huhn. Das Blatt fällt runter. Huhn mit Blatt im Schnabel rennt weg. Doch das wird selbst von Huhn gerammt. Eben diesem wird das Blatt aber [...]"',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Huhn rammt Huhn',
      },
      {
        property: 'og:description',
        content:
          '"Huhn rammt Huhn. Das Blatt fällt runter. Huhn mit Blatt im Schnabel rennt weg. Doch das wird selbst von Huhn gerammt. Eben diesem wird das Blatt aber [...]"',
      },
    ],
  },
}
</script>

<style scoped>
.huhn-rammt-huhn-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: bottom right;
}
.huhn-rammt-huhn-download-button {
  text-decoration: none;
}
</style>
