<template>
  <main class="card-rows-container" v-bind:class="rootClassName">
    <div class="card-row">
      <section class="character-teaser-container">
        <h1 class="card-heading">{{ murphysLarp }}</h1>
        <p class="card-text">{{ murphyLarpBeschreibung }}</p>
        <router-link to="/games/murphys-larp">
          <div class="more-link summary-games-button">
            <p class="card-link">{{ mehrLink }}</p>
          </div>
        </router-link>
      </section>
      <section class="character-teaser-container">
        <h1 class="card-heading">{{ assemblatron }}</h1>
        <p class="card-text">{{ assemblatronBeschreibung }}</p>
        <router-link to="/games/assemblatron">
          <div class="more-link summary-games-button1">
            <p class="card-link">{{ mehrLink }}</p>
          </div>
        </router-link>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'SummaryGames',
  props: {
    assemblatronBeschreibung: {
      type: String,
      default:
        'Ein Videospiel in einer von George Orwells "Nineteen Eighty-Four" inspirierten Dystopie, in der die Bevölkerung ununterbrochen in Fabriken Kampfroboter baut, welche die anderen, feindlichen Städte zerstören.',
    },
    assemblatron: {
      type: String,
      default: 'Assemblatron (Video Game)',
    },
    mehrLink: {
      type: String,
      default: 'Mehr ->',
    },
    murphyLarpBeschreibung: {
      type: String,
      default:
        'Alles was nur schiefgehen konnte, ging bei einem LARP schief. Ihr spielt die frustrierte Orga/SL (Organisation/Spielleitung) die sich über die “doofen” SC (Spielercharaktere) auslässt und die frustrierten SC, die sich über die “doofe” SL auslassen.',
    },
    rootClassName: String,
    murphysLarp: {
      type: String,
      default: "Murphy's LARP (Drama Game)",
    },
  },
}
</script>

<style scoped>
.summary-games-button:hover {
  opacity: 0.5;
}
.summary-games-button1:hover {
  opacity: 0.5;
}

</style>
