<template>
  <div class="assemblatron-container">
    <app-navigation rootClassName="navigation-root-class-name20"></app-navigation>
    <h1 class="page-title">
      <span>Assemblatron</span>
      <br />
    </h1>
    <span class="page-subtitle">
      <span>Resultat aus Godot Wild Jam #21, Mai 2020</span>
      <br />
    </span>
    <div class="text-body">
      <span>
        Ein Videospiel in einer von George Orwells &quot;Nineteen
        Eighty-Four&quot; inspirierten Dystopie, in der die Bevölkerung
        ununterbrochen in Fabriken Kampfroboter baut, welche die anderen,
        feindlichen Städte zerstören. Man spielt aber nur die Arbeitskraft im Kran
        und muss ähnlich wie bei der klassischen Arcade-Klauenmaschine Gegenstände
        hochhieven, im Spiel dann aber an die richtige Stelle tragen, um den
        jeweiligen Roboter Stück für Stück fertigzustellen. Der Kunststil der
        Texturen ist stark von Metropolis inspiriert.
      </span>
    </div>
    <a
      href="https://aquilaiovis.itch.io/assemblatron"
      target="_blank"
      rel="noreferrer noopener"
      class="assemblatron-download-button button"
    >
      <span class="button-text">
        itch.io Seite für Downloads (Linux, Windows, macOS)
      </span>
    </a>
    <app-kontakt rootClassName="kontakt-root-class-name13"></app-kontakt>
  </div>
</template>

<script>
import AppNavigation from '../../components/navigation'
import AppKontakt from '../../components/kontakt'

export default {
  name: 'Assemblatron',
  props: {},
  components: {
    AppNavigation,
    AppKontakt,
  },
  metaInfo: {
    title: 'OpenVisor - Assemblatron',
    meta: [
      {
        name: 'description',
        content:
          'Ein Videospiel in einer von George Orwells "Nineteen Eighty-Four" inspirierten Dystopie in der die Bevölkerung in Fabriken Kampfroboter baut welche die anderen, feindlichen Städte zerstören.',
      },
      {
        property: 'og:title',
        content: 'OpenVisor - Assemblatron',
      },
      {
        property: 'og:description',
        content:
          'Ein Videospiel in einer von George Orwells "Nineteen Eighty-Four" inspirierten Dystopie in der die Bevölkerung in Fabriken Kampfroboter baut welche die anderen, feindlichen Städte zerstören.',
      },
    ],
  },
}
</script>

<style scoped>
.assemblatron-container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  /* overflow-x: hidden; */
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/forest-background-luca-dross-1500w.jpg");
  background-position: top left;
}
.assemblatron-download-button {
  text-decoration: none;
}
</style>
